import React, { useState } from 'react';
import CreateStoreModal from '../modal/CreateStoreModal';
import CreateProductModal from '../modal/CreateProductModal';
import ReportModal from '../modal/ReportModal';
import { uploadFefoFile, uploadShelfControlFile, getRelatorioFefoExcel, getRelatorioShelfExcel } from '../homeApi';
import solteAqui from '../img/gallery-add.svg';
import './homepage.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HomePage: React.FC = () => {
  const [fefoFile, setFefoFile] = useState<File | null>(null);
  const [shelfControlFile, setShelfControlFile] = useState<File | null>(null);
  const [isFefoLoading, setIsFefoLoading] = useState(false);
  const [isShelfLoading, setIsShelfLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isStoreModalOpen, setIsStoreModalOpen] = useState(false);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [isFefoReportModalOpen, setIsFefoReportModalOpen] = useState(false);
  const [isShelfReportModalOpen, setIsShelfReportModalOpen] = useState(false)
  const [fefoFilters, setFefoFilters] = useState<any>({});
  const [shelfFilters, setShelfFilters] = useState<any>({});

  const handleOpenFefoModal = () => setIsFefoReportModalOpen(true);
  const handleOpenShelfModal = () => setIsShelfReportModalOpen(true);

  const handleFileDrop = (
    event: React.DragEvent<HTMLDivElement>,
    setFile: React.Dispatch<React.SetStateAction<File | null>>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      setFile(event.dataTransfer.files[0]);
      event.dataTransfer.clearData();
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleFefoUpload = async () => {
    if (!fefoFile) {
      setError('Por favor, selecione um arquivo FEFO para enviar.');
      return;
    }

    setIsFefoLoading(true);
    setError(null);

    try {
      const resultBlob = await uploadFefoFile(fefoFile);

      const url = window.URL.createObjectURL(new Blob([resultBlob]));
      const a = document.createElement('a');
      a.href = url;
      const timestamp = Date.now();
      a.download = `${timestamp}_resultado_fefo.xlsx`;
      a.click();

      window.URL.revokeObjectURL(url);

      toast.success('Arquivo FEFO enviado com sucesso!');
      setFefoFile(null);
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const handleShelfUpload = async () => {
    if (!shelfControlFile) {
      setError('Por favor, selecione um arquivo de controle de Shelf para enviar.');
      return;
    }

    setIsShelfLoading(true);
    setError(null);

    try {
      const resultBlob = await uploadShelfControlFile(shelfControlFile);

      const url = window.URL.createObjectURL(new Blob([resultBlob]));
      const a = document.createElement('a');
      a.href = url;
      const timestamp = Date.now();
      a.download = `${timestamp}_resultado_shelf.xlsx`;
      a.click();

      window.URL.revokeObjectURL(url);

      toast.success('Arquivo de controle de Shelf enviado com sucesso!');
      setShelfControlFile(null);
    } catch (err: unknown) {
      setError('Erro ao processar o arquivo de controle de Shelf. Tente novamente.');
    } finally {
      setIsShelfLoading(false);
    }
  };

  const handleCancel = () => {
    setFefoFile(null);
    setShelfControlFile(null);
    setError(null);
    setIsFefoLoading(false);
    setIsShelfLoading(false);
  };

  const handleFefoGenerateReport = async (filters: any) => {
    try {
      const resultBlob = await getRelatorioFefoExcel(filters);

      const url = window.URL.createObjectURL(new Blob([resultBlob]));
      const a = document.createElement('a');
      a.href = url;
      a.download = `${Date.now()}_relatorio_fefo.xlsx`;
      a.click();

      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error('Erro ao gerar relatório FEFO', err);
    }
  };

  const handleShelfGenerateReport = async (filters: any) => {
    try {
      const resultBlob = await getRelatorioShelfExcel(filters);

      const url = window.URL.createObjectURL(new Blob([resultBlob]));
      const a = document.createElement('a');
      a.href = url;
      a.download = `${Date.now()}_relatorio_shelf.xlsx`;
      a.click();

      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error('Erro ao gerar relatório Shelf', err);
    }
  };

  return (
    <div className="Lightbox">
      <div className="LightboxLogin">
        <div>
          <div className="SistemaDeControleDeFEFO">Sistema de Controle de Bebidas</div>
        </div>

        <div className="ButtonContainer">
          <div className="CtaBig Cadastro" onClick={() => setIsStoreModalOpen(true)}>
            <div className="ButtonText">Cadastrar Loja</div>
          </div>

          <div className="CtaBig Cadastro" onClick={() => setIsProductModalOpen(true)}>
            <div className="ButtonText">Cadastrar Produtos</div>
          </div>
        </div>

        <div className="TituloRelatorios">Geração de Leitura FEFO</div>
        <div
          className="Frame1000004010 drop-zone"
          onDrop={(event) => handleFileDrop(event, setFefoFile)}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          <div className="Senha">
            <div className="Input">
              <div className="Frame1000004002">
                <img
                  src={solteAqui}
                  alt="Gallery Add"
                  className="GalleryAdd"
                  width="48"
                  height="48"
                />
                <div className="ArrasteESolteOArquivoAqui">Arraste e solte o arquivo FEFO aqui</div>
              </div>
              <div
                className="CtaSmall"
                onClick={() => {
                  const inputElement = document.createElement('input');
                  inputElement.type = 'file';
                  inputElement.accept = '.xlsx';
                  inputElement.style.display = 'none';

                  inputElement.onchange = (event) => {
                    const target = event.target as HTMLInputElement | null;
                    const file = target?.files?.[0];
                    if (file) {
                      setFefoFile(file);
                    }
                  };

                  document.body.appendChild(inputElement);
                  inputElement.click();
                  document.body.removeChild(inputElement);
                }}
              >
                <span className="ComponentName">
                  {fefoFile ? fefoFile.name : 'Escolha o arquivo FEFO'}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="TituloRelatorios">Geração de Leitura Shelf</div>
        <div
          className="Frame1000004010 drop-zone"
          onDrop={(event) => handleFileDrop(event, setShelfControlFile)}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          <div className="Senha">
            <div className="Input">
              <div className="Frame1000004002">
                <img
                  src={solteAqui}
                  alt="Gallery Add"
                  className="GalleryAdd"
                  width="48"
                  height="48"
                />
                <div className="ArrasteESolteOArquivoAqui">
                  Arraste e solte o arquivo de controle de Shelf aqui
                </div>
              </div>
              <div
                className="CtaSmall"
                onClick={() => {
                  const inputElement = document.createElement('input');
                  inputElement.type = 'file';
                  inputElement.accept = '.xlsx';
                  inputElement.style.display = 'none';

                  inputElement.onchange = (event) => {
                    const target = event.target as HTMLInputElement | null;
                    const file = target?.files?.[0];
                    if (file) {
                      setShelfControlFile(file);
                    }
                  };

                  document.body.appendChild(inputElement);
                  inputElement.click();
                  document.body.removeChild(inputElement);
                }}
              >
                <span className="ComponentName">
                  {shelfControlFile ? shelfControlFile.name : 'Escolha o arquivo Shelf'}
                </span>
              </div>
            </div>
          </div>
        </div>

        {error && <div style={{ color: 'red' }}>{error}</div>}

        <div className="Frame1000003998">
          <div className="CtaBig Cancel" onClick={handleCancel}>
            <div className="ComponentName">Cancelar</div>
          </div>

          <button
            className="CtaBig Cadastro"
            onClick={handleFefoUpload}
            disabled={!fefoFile || isFefoLoading}
          >
            <div className="ButtonText">
              {isFefoLoading ? 'Enviando...' : 'Gerar relatório de FEFO'}
            </div>
          </button>

          <button
            className="CtaBig Cadastro"
            onClick={handleShelfUpload}
            disabled={!shelfControlFile || isShelfLoading}
          >
            <div className="ButtonText">
              {isShelfLoading ? 'Enviando...' : 'Gerar relatório de Shelf'}
            </div>
          </button>
        </div>

        <div className="TituloRelatorios">Geração de Relatórios Excel com base nos filtros</div>

        <div className="ButtonContainer">
          <div className="CtaBig Cadastro" onClick={handleOpenFefoModal}>
            <div className="ButtonText">Gerar Arquivo de Relatório FEFO</div>
          </div>

          <div className="CtaBig Cadastro" onClick={handleOpenShelfModal}>
            <div className="ButtonText">Gerar Arquivo de Relatório Shelf</div>
          </div>
        </div>

        <CreateStoreModal isOpen={isStoreModalOpen} onClose={() => setIsStoreModalOpen(false)} />
        <CreateProductModal isOpen={isProductModalOpen} onClose={() => setIsProductModalOpen(false)} />

        <ReportModal
          isOpen={isFefoReportModalOpen}
          onClose={() => setIsFefoReportModalOpen(false)}
          reportType="FEFO"
          onGenerateReport={handleFefoGenerateReport}
          filters={fefoFilters}
          setFilters={setFefoFilters}
        />
        <ReportModal
          isOpen={isShelfReportModalOpen}
          onClose={() => setIsShelfReportModalOpen(false)}
          reportType="SHELF"
          onGenerateReport={handleShelfGenerateReport}
          filters={shelfFilters}
          setFilters={setShelfFilters}
        />

      </div>

      <ToastContainer />
    </div>
  );
};

export default HomePage;
