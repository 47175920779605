import React, { useState, useEffect, useRef } from 'react';
import { getAllStores } from '../homeApi';
import './ReportModal.css';

interface Filters {
  inicio: string;
  fim: string;
  loja: string[];
}

interface ReportModalProps {
  isOpen: boolean;
  onClose: () => void;
  reportType: 'FEFO' | 'SHELF';
  onGenerateReport: (filters: Filters) => void;
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}

interface Store {
  id: string;
  nomeFantasia: string;
}

const ReportModal: React.FC<ReportModalProps> = ({
  isOpen,
  onClose,
  reportType,
  onGenerateReport,
  filters,
  setFilters,
}) => {
  const [stores, setStores] = useState<Store[]>([]);
  const [selectedStores, setSelectedStores] = useState<Store[]>([]);
  const modalContentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      const fetchStores = async () => {
        try {
          const response = await getAllStores();
          const filteredStores = response.filter(
            (store: Store) => !selectedStores.some((s) => s.id === store.id)
          );
          setStores(filteredStores);
        } catch (error) {
          console.error('Erro ao carregar as lojas:', error);
        }
      };
      fetchStores();
    }
  }, [isOpen, selectedStores]);

  const handleAddStore = (store: Store) => {
    setSelectedStores((prevSelected) => [...prevSelected, store]);
    setStores((prevStores) => prevStores.filter((s) => s.id !== store.id));
  };

  const handleRemoveStore = (store: Store) => {
    setSelectedStores((prevSelected) => prevSelected.filter((s) => s.id !== store.id));
    setStores((prevStores) => [...prevStores, store]);
  };

  const handleSubmit = () => {
    const loja = selectedStores.map((store) => store.id);
    onGenerateReport({ ...filters, loja });
    onClose();
  };

  const handleFilterChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(e.target as Node)
    ) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div style={{ minWidth: 700}} className="modal-content" ref={modalContentRef}>
        <div>
          <div className="CadastrarProduto">Gerar Relatório {reportType}</div>
        </div>
        <div className="form-group">
          <label className="OpcoesProduto" htmlFor="inicio">Data Início:</label>
          <input
            type="date"
            name="inicio"
            value={filters.inicio}
            onChange={handleFilterChange}
          />
        </div>
        <div className="form-group">
          <label className="OpcoesProduto" htmlFor="fim">Data Fim:</label>
          <input
            type="date"
            name="fim"
            value={filters.fim}
            onChange={handleFilterChange}
          />
        </div>
        <div className="form-group">
          <label className="OpcoesProduto" htmlFor="loja">Lojas:</label>
          <div className="store-selection">
            <div className="available-stores">
              <h4 className="OpcoesProduto">Lojas Disponíveis</h4>
              <select
                multiple
                onChange={(e) => {}}
                size={5}
                className='select-report'
              >
                {stores.map((store) => (
                  <option className='option-report' key={store.id} value={store.id}>
                    {store.nomeFantasia}
                  </option>
                ))}
              </select>
              <div
                style={{ marginTop: 10 }}
                className="CtaBig Cadastro"
                onClick={() => {
                  const selectedOption = document.querySelector(
                    'select option:checked'
                  ) as HTMLOptionElement;
                  if (selectedOption) {
                    const store = stores.find((s) => s.id === selectedOption.value);
                    if (store) handleAddStore(store);
                  }
                }}
              >
                <div className="ButtonText">Adicionar</div>
              </div>
            </div>
            <div className="selected-stores">
              <h4 className="OpcoesProduto">Lojas Selecionadas</h4>
              <ul className="OpcoesProduto">
                {selectedStores.map((store) => (
                  <div className='OpcoesProduto-div' key={store.id}>
                    <div className='div-report'>
                      {store.nomeFantasia}
                    </div>
                    <div className="CtaBig Cadastro" onClick={() => handleRemoveStore(store)}>
                      <div className="ButtonText">Remover</div>
                    </div>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 10 }} className="CtaBig Cadastro">
          <div className="ButtonText" onClick={handleSubmit}>Gerar Relatório</div>
        </div>
      </div>
    </div>
  );
};

export default ReportModal;
