import axios from 'axios';

export const uploadFefoFile = async (file: File): Promise<Blob> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/bebidas/fefo-control`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
    });

    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error.response?.data instanceof Blob) {
        const errorText = await error.response.data.text();

        try {
          const errorJson = JSON.parse(errorText.trim());

          throw { message: errorJson.message || 'Erro ao enviar o arquivo' };
        } catch {
          const errorJson = JSON.parse(errorText.trim());
          throw { message: errorJson.message };
        }
      }
      throw { message: 'Erro ao enviar o arquivo. Tente novamente.' };
    } else {
      throw { message: 'Erro desconhecido ao enviar o arquivo.' };
    }
  }
};

export const uploadShelfControlFile = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/bebidas/shelf-control`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
    });

    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Erro ao enviar arquivo');
    } else {
      throw new Error('Erro desconhecido');
    }
  }
};

export const createStore = async (storeData: { nomeFantasia: string; endereco: string }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/stores`,
      storeData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Erro ao criar a loja');
    } else {
      throw new Error('Erro desconhecido');
    }
  }
};

export const createProducts = async (productsData: { nomeProduto: string; sap: string }[]) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/products`,
      productsData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Erro ao cadastrar o produto');
    } else {
      throw new Error('Erro desconhecido');
    }
  }
};

export const getRelatorioShelfExcel = async (
  filtros: { inicio?: string; fim?: string; lojas?: string[] }
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/bebidas-filtros/relatorio-shelf-excel`,
      {
        params: filtros,
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

      return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Erro ao gerar o relatório SHELF Excel');
    } else {
      throw new Error('Erro desconhecido');
    }
  }
};

export const getRelatorioFefoExcel = async (
  filtros: { inicio?: string; fim?: string; lojas?: string[] }
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/bebidas-filtros/relatorio-fefo-excel`,
      {
        params: filtros,
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Erro ao gerar o relatório FEFO Excel');
    } else {
      throw new Error('Erro desconhecido');
    }
  }
};

// Retorna todas as lojas, sem paginação
export const getAllStores = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/stores`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Erro ao buscar todas as lojas');
    } else {
      throw new Error('Erro desconhecido');
    }
  }
};
